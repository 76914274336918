import React from "react"
import Header from "./header"
import Contact from "../components/Contact"
import Bio from "../components/bio"

class Layout extends React.Component {
  render() {
    const { location, title, children, isBasePath } = this.props
    const contactOk =
      location.pathname.includes("/omnie") || location.pathname === "/"

    return (
      <div
        key={title}
        className="flex flex-col min-h-screen text-gray-900 bg-gray-200"
      >
        <Header location={location} isBasePath={isBasePath} title={title} />
        <main className="text-gray-800">
          <Bio />
          <div id="mainContainer" className="container mx-auto">
            {children}
            {contactOk && <Contact />}
          </div>
        </main>
      </div>
    )
  }
}

export default Layout
