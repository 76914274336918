import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SectionLayout from "../components/SectionLayout"
import Icon from "@mdi/react"
import { mdiPhoneInTalk, mdiMessageText, mdiAt, mdiOpenInNew } from "@mdi/js"

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            email
            phone
            phoneExtra
          }
        }
      }
    `}
    render={(data) => {
      const mainClass = "md:flex mt-5"
      const iconClasses = "md:flex-1 text-sm text-gray-600 px-8"
      const textClasses =
        "md:flex-1 text-xl font-bold text-gray-800 px-8 md:px-4"
      const extraTextClasses = "md:flex-1 text-sm md:ml-5 text-gray-600 px-8"
      const allData = [
        {
          mainClass: mainClass,
          iconText: "Napisz SMS",
          link: "sms:" + data.site.siteMetadata.phone,
          linkClass: "sms-link",
          iconPath: mdiMessageText,
          text: data.site.siteMetadata.phone,
          extraText: "",
          specificExtraTextClasses: "",
        },
        {
          mainClass: mainClass + " phone-link",
          iconText: "Zadzwoń",
          link: "tel:" + data.site.siteMetadata.phone,
          linkClass: "",
          iconPath: mdiPhoneInTalk,
          text: data.site.siteMetadata.phone,
          extraText: data.site.siteMetadata.phoneExtra,
          specificExtraTextClasses: "underline",
        },
        {
          mainClass: mainClass,
          iconText: "Email",
          link: "mailto:" + data.site.siteMetadata.email,
          linkClass: "email-link",
          iconPath: mdiAt,
          text: data.site.siteMetadata.email,
          extraText: "",
          specificExtraTextClasses: "",
        },
      ]
      return (
        <SectionLayout sectionname="Kontakt" notFlex={true}>
          <div className="">
            {allData.map(
              ({
                mainClass,
                iconText,
                link,
                linkClass,
                iconPath,
                text,
                extraText,
                specificExtraTextClasses,
              }) => (
                <div key={iconText} className={mainClass}>
                  <div className={iconClasses}>
                    <a className={linkClass} href={link}>
                      <span>
                        <Icon
                          path={iconPath}
                          className="mr-5 inline-block"
                          size="15px"
                        />
                        {iconText}
                        <Icon
                          className="ml-1 align-text-bottom inline-block"
                          path={mdiOpenInNew}
                          size="1rem"
                        />
                      </span>
                    </a>
                  </div>
                  <div className={textClasses}>{text}</div>
                  <div
                    className={
                      extraTextClasses + " " + specificExtraTextClasses
                    }
                  >
                    {extraText}
                  </div>
                </div>
              )
            )}
          </div>
        </SectionLayout>
      )
    }}
  />
)

export default Contact
