import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      headerimg: file(absolutePath: { regex: "/header-img.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, srcSetBreakpoints: [75, 100, 150, 200, 250, 300, 400, 500]) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          description
        }
      }
    }
  `)

  const { author, description } = data.site.siteMetadata
  return (
    <div
      className="site-logo relative bg-gray-900 flex"
    >
      <div className=" flex items-center md:justify-center max-w-4xl mx-auto px-4 py-8 mt-32 md:mt-20 md:p-6 w-full">
        <div className="flex-1 z-10 lg:mr-5">
          <h1 className="text-gray-300 z-10">{author}</h1>
          <h3 className="text-gray-500 z-10">{description}</h3>
        </div>
        <div className="flex-1">
          <Image fluid={data.headerimg.childImageSharp.fluid} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Bio
