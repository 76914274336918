import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import Icon from "@mdi/react"
import {
  mdiHome,
  mdiHospitalBuilding,
  mdiAccountQuestion,
  mdiCalendarRange,
  mdiCardAccountPhone,
} from "@mdi/js"

const Header = ({ isBasePath, title }) => {
  const userNav = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          navigation {
            name
            uri
          }
        }
      }
    }
  `)
  const groups = userNav.site.siteMetadata.navigation
  const linkClass =
    "block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white w-1/2 md:w-auto"
  const icons = [
    mdiAccountQuestion,
    mdiHospitalBuilding,
    mdiCalendarRange,
    mdiCardAccountPhone,
  ]
  return (
    <header
      role="banner"
      className="header-background fixed p-2 z-20 w-full mt-0"
    >
      <div className="container mx-auto flex flex-wrap items-center pt-2">
        <div className="flex w-full content-center justify-between md:w-1/3">
          <div className="">
            <Link
              className="flex items-center no-underline text-teal-300 hover:text-white"
              to="/"
            >
              <Icon path={mdiHome} className="mr-5 inline-block" />
              {title}
            </Link>
          </div>
        </div>
        <nav className="flex flex-wrap md:w-2/3 md:justify-end">
          {groups.map(({ name, uri }, index) =>
            isBasePath ? (
              <ScrollLink
                key={name + uri}
                className={linkClass}
                href={"#" + uri}
                hashSpy={true}
                to={uri}
                smooth={true}
                offset={-70}
                // containerId="mainContainer"
                duration={500}
              >
                <Icon
                  path={icons[index]}
                  className="align-bottom ml-5 mr-1 inline-block"
                />
                {name}
              </ScrollLink>
            ) : (
              <Link key={name + uri} className={linkClass} to={"/#" + uri}>
                <Icon
                  path={icons[index]}
                  className="align-bottom ml-5 mr-1 inline-block"
                />
                {name}
              </Link>
            )
          )}
        </nav>
      </div>
    </header>
  )
}

export default Header
