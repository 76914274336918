import React from "react"
import Icon from "@mdi/react"
import { mdiLinkVariant } from "@mdi/js"

const SectionLayout = ({ children, sectionname, notFlex }) => {
  const sectionuri = sectionname
    .replace(/ /g, "")
    .replace(/\//g, "")
    .toLowerCase()
  const flexClass = notFlex
    ? "py-6 justify-center"
    : "flex flex-wrap pt-6 justify-center	"
  return (
    <div className="section pt-6">
      <div>
        <h2 id={sectionuri} className="sectionh2 pl-2 sm:pl-0">
          <a className="sectionlink self-center" href={"#" + sectionuri}>
            <Icon
              className="mr-1"
              color="#22543d"
              path={mdiLinkVariant}
              aria-label="Link"
              size="1rem"
            />
          </a>
          <span>{sectionname}</span>
        </h2>
        <hr className="w-1/4 border-gray-600"></hr>
      </div>
      <div className={flexClass}>{children}</div>
    </div>
  )
}

export default SectionLayout
