import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, tags, title }) => {
  const { site, headerimg } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            extraTags
            author
            siteUrl
          }
        }
        headerimg: file(absolutePath: { regex: "/header-img.jpg/" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `
  )
  //dodaj cookie info o GA
  // const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  // console.log("cano", canonical)
  const author = site.siteMetadata.author
  const metaDescription =
    description || author + " " + site.siteMetadata.description
  const metaImage = headerimg.childImageSharp.fluid
  const keywords = [
    author,
    title,
    ...site.siteMetadata.extraTags,
    ...tags,
  ].join(",")
  const fullTitle = `${author} | Ortopeda | ${title}`
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      titleTemplate={
        title === site.siteMetadata.title
          ? `%s`
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: fullTitle,
        },
        {
          property: `og:author`,
          content: author,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: `${site.siteMetadata.siteUrl}${metaImage.src}`,
        },
        {
          property: "og:image:width",
          content: metaImage.presentationWidth,
        },
        {
          property: "og:image:height",
          content: metaImage.presentationHeight,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: fullTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Physician",
          "currenciesAccepted": "PLN",
          "paymentAccepted": "Cash",
          "address": {
            "@type": "PostalAddress",
            "addressCountry": "PL",
            "addressLocality": "Poznan",
            "addressRegion": "Wielkopolska",
            "postalCode": "61-433",
            "streetAddress": "Opolska 58"
          },
          "location": [
          {
            "@type": "PostalAddress",
            "addressCountry": "PL",
            "addressLocality": "Poznan",
            "addressRegion": "Wielkopolska",
            "postalCode": "61-433",
            "streetAddress": "Opolska 58"
          },
          {
            "@type": "PostalAddress",
            "addressCountry": "PL",
            "addressLocality": "Piła",
            "addressRegion": "Wielkopolska",
            "postalCode": "64-920",
            "streetAddress": "aleja Wojska Polskiego 43"
          }
          ],
          "name": "Jan Długosz",
          "priceRange": "$$",
          "telephone": "+48668179070",
          "url": "https://orthospec.pl",
          "image": "${site.siteMetadata.siteUrl}${metaImage.src}"
        }
      `}
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
